.socials {
  background-color: var(--backgroundColor3);
  aspect-ratio: 1;
  cursor: url("link.6ebb9d0c.png"), pointer;
  box-shadow: inset 0 0 25px var(--shadowGlow2), 0 0 30px var(--shadowGlow2);
  border: var(--outlineGlow);
  border-radius: 100%;
  align-content: center;
  width: 180px;
  transition: all .4s;
  position: absolute;
}

.socials:hover {
  scale: 1.05;
}

.socials svg {
  fill: #fff;
}

#socialtwitter, #socialDiscord, #socialreddit, #sociallinkedin {
  transform: scale(.9);
}

.socials#socialtwitter:hover {
  background-color: #1da1f2;
  border: 2px solid #adddfa;
  box-shadow: inset 0 0 35px #7dc8f7, 0 0 50px #7dc8f780;
}

#socialDiscord:hover {
  background-color: #5865f2;
  border: 2px solid #b8bdfa;
  box-shadow: inset 0 0 35px #8892f6, 0 0 50px #8892f680;
}

#socialreddit:hover {
  background-color: #ff4500;
  border: 2px solid #ffb499;
  box-shadow: inset 0 0 35px #ff8f66, 0 0 50px #ff8f6680;
}

#sociallinkedin:hover {
  background-color: #0e76a8;
  border: 2px solid #adddfa;
  box-shadow: inset 0 0 35px #7dc8f7, 0 0 50px #7dc8f780;
}

#socialtwitter svg, #socialreddit svg, #sociallinkedin svg, #socialDiscord svg {
  fill: var(--mainFontColor);
  transition: all .2s ease-in-out;
}

#socialtwitter svg:hover {
  filter: drop-shadow(0 0 10px #7dc8f7);
  transform: scale(1.05);
}

#socialDiscord svg:hover {
  filter: drop-shadow(0 0 10px #8892f6);
  transform: scale(1.05);
}

#socialreddit svg:hover {
  filter: drop-shadow(0 0 10px #ff8f66);
  transform: scale(1.05);
}

#sociallinkedin svg:hover {
  filter: drop-shadow(0 0 10px #2fb2ee);
  transform: scale(1.05);
}
/*# sourceMappingURL=index.ca2712a4.css.map */
