.socials {
  background-color: var(--backgroundColor3);
  position: absolute;
  width: 180px;
  aspect-ratio: 1/1;
  border-radius: 100%;
  cursor: url(./images/link.png), pointer;
  box-shadow: inset 0 0 25px var(--shadowGlow2), 0 0 30px var(--shadowGlow2);
  align-content: center;
  border: var(--outlineGlow);
  transition: 0.4s ease;
}

.socials:hover {
  scale: 1.05;
}

.socials svg {
  fill: white;
}

#socialtwitter {
  transform: scale(0.9);
}

#socialDiscord {
  transform: scale(0.9);
}

#socialreddit {
  transform: scale(0.9);
}

#sociallinkedin {
  transform: scale(0.9);
}

.socials#socialtwitter:hover {
  background-color: #1da1f2;
  border: 2px solid hsl(203, 89%, 83%);
  box-shadow: inset 0 0 35px hsl(203, 89%, 73%),
    0 0 50px hsla(203, 89%, 73%, 0.5);
  /* padding: 10px; */
}
#socialDiscord:hover {
  background-color: #5865f2;
  border: 2px solid hsl(235, 86%, 85%);

  box-shadow: inset 0 0 35px hsl(235, 86%, 75%),
    0 0 50px hsla(235, 86%, 75%, 0.5);
}
#socialreddit:hover {
  background-color: #ff4500;
  border: 2px solid hsl(16, 100%, 80%);
  box-shadow: inset 0 0 35px hsl(16, 100%, 70%),
    0 0 50px hsla(16, 100%, 70%, 0.5);
}
#sociallinkedin:hover {
  background-color: #0e76a8;
  border: 2px solid hsl(203, 89%, 83%);
  box-shadow: inset 0 0 35px hsl(203, 89%, 73%),
    0 0 50px hsla(203, 89%, 73%, 0.5);
}

#socialtwitter svg {
  transition: 0.2s ease-in-out;
  fill: var(--mainFontColor);
}
#socialreddit svg {
  transition: 0.2s ease-in-out;
  fill: var(--mainFontColor);
}
#sociallinkedin svg {
  transition: 0.2s ease-in-out;
  fill: var(--mainFontColor);
}
#socialDiscord svg {
  transition: 0.2s ease-in-out;
  fill: var(--mainFontColor);
}

#socialtwitter svg:hover {
  transform: scale(1.05);
  filter: drop-shadow(0 0 10px hsl(203, 89%, 73%));
}
#socialDiscord svg:hover {
  transform: scale(1.05);
  filter: drop-shadow(0 0 10px hsl(235, 86%, 75%));
}
#socialreddit svg:hover {
  transform: scale(1.05);
  filter: drop-shadow(0 0 10px hsl(16, 100%, 70%));
}
#sociallinkedin svg:hover {
  transform: scale(1.05);
  filter: drop-shadow(0 0 10px hsl(199, 85%, 56%));
}
